import { Anrede, Hoehe, Kanton, Land, MyFormData, Sprache } from "./MyFormData";
import * as Yup from "yup";
import React, { useEffect, useState } from "react";
import { Formik, FormikProps } from "formik";
import { Form, Spinner } from "react-bootstrap";
import RegistrierungEmail from "./RegistrierungEMail";
import { RegistrierungStammdaten, BetriebskonzeptImkerData } from "./RegistrierungStammdaten";
import Button from "../../components/reusable/button";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import RegistrierungImkerAngaben from "./RegistrierungImkerAngaben";
import RegistrierungBestaetigung from "./RegistrierungBestaetigung";
import { GetImkerRegistrierung, RegistrierungImker } from "../../services/backend/ImkerRegistrierung";
import { GetImkerByEMailDto, ImkerStatus, KickoffterminAuswahlDto } from "../../generated/api/generated-api";
import { GetKickoffTermine } from "../../services/backend/Kickofftermine";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AuthContext, SignInResponse } from "../../authorization/AuthProvider";
import { FindImkerByEMail as FindBetriebskonzeptImkerByEMail } from "../../services/backend/BetriebskonzeptImkerRegistrierung";
import RegistrierungBetriebskonzeptPasswort from "./RegistrierungBetreibskonzeptPasswort";
import { RedirectToHiddenLogin } from "../../services/HiddenLogin";

var initialValues: MyFormData = {
  email: "",
  sprache: undefined,
  anrede: undefined,
  vorname: "",
  nachname: "",
  strasseNr: "",
  plz: undefined,
  ort: "",
  land: undefined,
  tel: "",
  telValidation: "",
  emailValidation: "",
  password: "",
  passwordValidation: "",
  imker_seitWann: "",
  imker_anzahlVoelker: undefined,
  beutenSystemAuswahl: "",
  imker_beutensystem_schweizerkasten: false,
  imker_beutensystem_magazin: false,
  imker_varroaunterlage: undefined,
  imker_herausnehmbareWaben: undefined,
  imker_hoehe: undefined,
  imker_mitgliedVerein: undefined,
  imker_sektion: "",
  imker_kanton: undefined,
  imker_kickoffTermin: undefined,
  bestaetigung_agb: false,
  bestaetigung_kostenpflichtig: false,
};

// DEBUG: Prefill first pages to debug
// initialValues = {
//   email: "yves@innofactory.ch",
//   sprache: Sprache.de,
//   anrede: Anrede.m,
//   vorname: "Yves",
//   nachname: "Wälchli",
//   strasseNr: "Strasse 1",
//   plz: 4522,
//   ort: "Rüttenen",
//   land: Land.ch,
//   tel: "079",
//   telValidation: "079",
//   emailValidation: "yves@innofactory.ch",
//   password: "hans",
//   passwordValidation: "hans",
//   imker_seitWann: "2019",
//   imker_anzahlVoelker: 3,
//   beutenSystemAuswahl: "",
//   imker_beutensystem_schweizerkasten: false,
//   imker_beutensystem_magazin: false,
//   imker_varroaunterlage: true,
//   imker_herausnehmbareWaben: true,
//   imker_hoehe: Hoehe._901bis1200,
//   imker_mitgliedVerein: true,
//   imker_sektion: "Thunesien",
//   imker_kanton: Kanton.AG,
//   imker_kickoffTermin: undefined,
//   bestaetigung_agb: false,
//   bestaetigung_kostenpflichtig: false,
// };

const CustomForm = styled(Form)`
  label {
    font-size: 0.9rem;
  }
`;

const Header = styled.h2`
  margin-bottom: 2rem;
`;

const LaodingDisable = styled.div<{ loading: boolean }>`
  /* cursor: ${({ loading }) => (loading ? "not-allowed" : "pointer")};
  opacity: ${({ loading }) => (loading ? 0.5 : 1)}; */
  visibility: ${({ loading }) => (loading ? "hidden" : "inherit")};
`;

//TODO: Refactor, ufruume!
function Registrierung() {
  const [queryParameters] = useSearchParams();
  const teilnahmeErneuern = queryParameters.get("erneuern") !== null;
  console.log("erneuern:" + teilnahmeErneuern);

  let auth = React.useContext(AuthContext);

  const [step, setStep] = useState<number>(1);
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingImker, setIsLoadingImker] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [isGesundheitsprogrammUser, setIsGesundheitsprogrammUser] = useState(false);
  const [isSubmitFailed, setIsSubmitFailed] = useState(false);
  const [imkerStatus, setImkerStatus] = useState<ImkerStatus | undefined>(undefined);

  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  //https://www.carlrippon.com/drop-down-data-binding-with-react-hooks/
  const [kickoffTerminAuswahl, setKickoffTerminAuswahl] = useState<KickoffterminAuswahlDto[]>([{ id: 0, text: "" }]);
  const [isLoading, setIsLoading] = useState(false);

  const [betriebskonzeptImkerData, setBetriebskonzeptImkerData] = useState<BetriebskonzeptImkerData>();

  useEffect(() => {
    LoadData();
  }, [auth.user]);

  const LoadData = async () => {
    setIsLoading(true);

    var kickoff = await GetKickoffTermine();
    setKickoffTerminAuswahl(kickoff!);

    if (teilnahmeErneuern && auth.user) {
      try {
        const registrierung = await GetImkerRegistrierung(auth.user.id, auth.user.token)!;

        setImkerStatus(registrierung!.status);

        if (registrierung!.status !== 50 && registrierung!.status !== 40) return;
        if (registrierung!.status === 50) {
          // abgehelehnt, nur stammdaten vorladen
          PreloadStammdaten(registrierung!.data);
        }
        if (registrierung!.status === 40) {
          PreloadStammdaten(registrierung!.data);
          PreloadLand(registrierung!.data);
          PreloadImkerAngaben(registrierung!.data);
          // gekündet durch imker, zusätzlich imkerangaben + land vorladen
        }

        setStep(3);
      } catch (e) {
        console.warn(e);
      }
    }

    setIsLoading(false);
  };

  function PreloadStammdaten(data: MyFormData) {
    initialValues.vorname = data.vorname;
    initialValues.nachname = data.nachname;
    initialValues.strasseNr = data.strasseNr;
    initialValues.plz = data.plz;
    initialValues.ort = data.ort;
    initialValues.email = data.email;
    initialValues.emailValidation = data.email;
    initialValues.sprache = data.sprache;
    initialValues.anrede = data.anrede;
    initialValues.tel = data.tel;
    initialValues.telValidation = data.tel;
  }

  function PreloadLand(data: MyFormData) {
    initialValues.land = data.land;
  }

  function PreloadImkerAngaben(data: MyFormData) {
    initialValues.imker_seitWann = data.imker_seitWann;
    initialValues.imker_anzahlVoelker = data.imker_anzahlVoelker;
    initialValues.imker_beutensystem_schweizerkasten = data.imker_beutensystem_schweizerkasten;
    initialValues.imker_beutensystem_magazin = data.imker_beutensystem_magazin;
    initialValues.imker_varroaunterlage = data.imker_varroaunterlage;
    initialValues.imker_herausnehmbareWaben = data.imker_herausnehmbareWaben;
    initialValues.imker_hoehe = data.imker_hoehe;
    initialValues.imker_mitgliedVerein = data.imker_mitgliedVerein;
    initialValues.imker_sektion = data.imker_sektion;
    initialValues.imker_kanton = data.imker_kanton;
  }

  const validationSchemaEmail = Yup.object({
    email: Yup.string().email("Ungültige E-Mail-Adresse").required("*"), //TODO: E-Mail validierung, Übesetzung, PLZ validierung
  });

  const validationSchemaBetriebskonzeptPasswort = Yup.object({
    password: Yup.string().required("*"),
  });

  const validationSchemaStammdaten = Yup.object({
    sprache: Yup.string().oneOf(Object.values(Sprache)).required("*"),
    anrede: Yup.string().oneOf(Object.values(Anrede)).required("*"),
    vorname: Yup.string().required("*"),
    nachname: Yup.string().required("*"),
    strasseNr: Yup.string().required("*"),
    plz: Yup.number().typeError(t("anmeldung.ungueltigePostleitzahl").toString()).required("*"),
    ort: Yup.string().required("*"),
    land: Yup.string().oneOf(Object.values(Land)).required("*"),
    tel: Yup.string()
      .matches(/^[0-9+]+$/, t("anmeldung.telValidation").toString())
      .required("*"),
    telValidation: Yup.string()
      .required("*")
      .oneOf([Yup.ref("tel")], t("anmeldung.telUebereinstimmung").toString()),
  });

  const validationSchemaStammdatenEmail = Yup.object({
    email: Yup.string().email(t("anmeldung.ungueltigeEmail").toString()).required("*"),
    emailValidation: Yup.string()
      .email(t("anmeldung.ungueltigeEmail").toString())
      .required("*")
      .oneOf([Yup.ref("email")], t("anmeldung.emailUebereinstimmung").toString()),
  });

  const validationSchemaStammdatenPassword = Yup.object({
    password: Yup.string().required("*"),
    passwordValidation: Yup.string()
      .required("*")
      .oneOf([Yup.ref("password")], t("anmeldung.passwortUebereinstimmung").toString()),
  });

  const validationSchemaImkerAngaben = Yup.object({
    imker_seitWann: Yup.string()
      .matches(/^[0-9]{4}$/, t("anmeldung.ungueltigeJahreszahl").toString())
      .required("*"),
    imker_anzahlVoelker: Yup.number().typeError(t("anmeldung.ungueltigeZahl").toString()).required("*"),
    imker_beutensystem_schweizerkasten: Yup.boolean(),
    imker_beutensystem_magazin: Yup.boolean(),
    imker_varroaunterlage: Yup.boolean().required("*"),
    imker_herausnehmbareWaben: Yup.boolean().required("*"),
    imker_hoehe: Yup.string().oneOf(Object.values(Hoehe)).required("*"),
    imker_mitgliedVerein: Yup.boolean().required("*"),
    imker_sektion: Yup.string().required("*"),
    imker_kanton: Yup.string().oneOf(Object.values(Kanton)).required("*"),
  }).test("beutensystemTest", (obj) => {
    if (obj.imker_beutensystem_magazin || obj.imker_beutensystem_schweizerkasten) {
      return true;
    }

    return new Yup.ValidationError("*", null, "beutenSystemAuswahl");
  });

  const validationSchemaImkerAngabenKickoff = Yup.object({
    imker_kickoffTermin: Yup.number().required("*"),
  });

  const validationSchemaBestaetigung = Yup.object({
    bestaetigung_agb: Yup.bool().oneOf([true], "*"),
    bestaetigung_kostenpflichtig: Yup.bool().oneOf([true], "*"),
  });

  function getValidationSchema(step: number) {
    if (step === 1) return validationSchemaEmail;

    if (step === 2) return validationSchemaBetriebskonzeptPasswort;

    if (step === 3 && betriebskonzeptImkerData?.betriebskonzeptImker) return validationSchemaStammdaten;
    if (step === 3 && !betriebskonzeptImkerData?.betriebskonzeptImker && !teilnahmeErneuern) return validationSchemaStammdaten.concat(validationSchemaEmail).concat(validationSchemaStammdatenPassword);
    if (step === 3 && teilnahmeErneuern) return validationSchemaStammdaten.concat(validationSchemaStammdatenEmail);

    if (step === 4 && !teilnahmeErneuern) return validationSchemaImkerAngaben.concat(validationSchemaImkerAngabenKickoff);
    if (step === 4 && teilnahmeErneuern && imkerStatus === 40) return validationSchemaImkerAngaben; // gekündigt durch imker
    if (step === 4 && teilnahmeErneuern && imkerStatus === 50) return validationSchemaImkerAngaben.concat(validationSchemaImkerAngabenKickoff); // abgelehnt

    if (step === 5) return validationSchemaBestaetigung;
  }

  const handleSubmit = async (values: MyFormData, actions: any) => {
    setIsLoadingSubmit(true);
    setIsSubmitFailed(false);

    var teilnahmeErneuern_: boolean = teilnahmeErneuern && (imkerStatus === 40 || imkerStatus === 50);

    //TODO: Error handling
    RegistrierungImker(values, betriebskonzeptImkerData?.betriebskonzeptImker!, teilnahmeErneuern_)
      .then(() => {
        actions.setSubmitting(false);

        if (teilnahmeErneuern_) {
          navigate("/meinprogramm");
        } else {
          auth.signin(values.email, values.password, (response: SignInResponse) => {
            //TODO: Error handling
            console.log(JSON.stringify(response));
            RedirectToHiddenLogin(response.userId);
          });
        }
      })
      .catch(() => {
        setIsSubmitFailed(true);
      })
      .finally(() => {
        setIsLoadingSubmit(false);
      });
  };

  const handleNextStep = async (formikProps: FormikProps<MyFormData>) => {
    console.log(formikProps.values);

    const errors = await formikProps.validateForm();
    if (Object.keys(errors).length === 0) {
      if (step === 5) {
        // Letzte Seite
        formikProps.submitForm();
      } else {
        if (step === 1) {
          // E-Mail eingabe
          setIsLoadingImker(true);
          setIsGesundheitsprogrammUser(false);
          FindBetriebskonzeptImkerByEMail(formikProps.values.email)
            .then((values) => {
              if (values.gesundheitsprogrammAlreadyRegistered) {
                //TODO: Nice Message box
                setIsGesundheitsprogrammUser(true);
              } else if (values.imkerFound) {
                // Betriebskonzept Imker > Werte vorfüllen
                fillBetriebkonzeptImkerValues(values);
                // Betriebskonzept Imker > Zum Passwort
                setStep(2);
              } else {
                // Neue E-Mail Adresse: Direkt zur Stammdateneingabe
                setStep(3);
              }
            })
            .finally(() => {
              setIsLoadingImker(false);
            });
        } else if (step === 2) {
          // Betriebskonzept-Imker Passworteingabe
          setIsLoadingSubmit(true);
          auth.signin(formikProps.values.email, formikProps.values.password, (response: SignInResponse) => {
            console.log(JSON.stringify(response));
            if (response.isUnauthorized) {
              setIsLoginError(true);
            } else {
              setIsLoginError(false);
              setStep(3);
            }

            setIsLoadingSubmit(false);
          });
        } else {
          // Kein Betriebskonzept Imker > Normale registrierung
          // Passworteingabe überspringen
          setStep(step + 1);
        }
      }
    }
  };

  function fillBetriebkonzeptImkerValues(imker: GetImkerByEMailDto) {
    let betriebskonzeptImkerData: BetriebskonzeptImkerData;
    if (imker.imkerFound) {
      betriebskonzeptImkerData = {
        betriebskonzeptImker: true,
        vorname: imker.imker?.vorname!,
        nachname: imker.imker?.nachname!,
        strasseNr: imker.imker?.strasseNr!,
        plz: imker.imker?.plz,
        ort: imker.imker?.ort!,
      };
    } else {
      betriebskonzeptImkerData = {
        betriebskonzeptImker: false,
        vorname: "",
        nachname: "",
        strasseNr: "",
        plz: 0,
        ort: "",
      };
    }

    setBetriebskonzeptImkerData(betriebskonzeptImkerData);
  }

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLFormElement>, formikProps: FormikProps<MyFormData>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleNextStep(formikProps);
    }
  };

  return (
    <>
      <Header>{t("anmeldung.titel")}</Header>
      {imkerStatus === 40 && teilnahmeErneuern && <div style={{ color: "red", marginBottom: "20px" }}>{t("anmeldung.hinweisNeuanmeldung")}</div>}
      {(isLoading || isLoadingImker) && <Spinner as="span" animation="border" role="status" aria-hidden="true" style={{ marginLeft: "10px", color: "gray" }} />}

      {new Date() < new Date("2025-07-01") ? (
        <p style={{ color: "red", display: "inline", marginRight: "10px" }}>{t("uebersicht.anmeldungDatumMoeglich")}</p>
      ) : (
        <LaodingDisable loading={isLoading || isLoadingImker}>
          <Formik initialValues={initialValues} validationSchema={getValidationSchema(step)} validateOnMount={false} validateOnChange={false} validateOnBlur={false} validateOnSubmit={true} onSubmit={handleSubmit} enableReinitialize={true}>
            {(formikProps) => (
              <CustomForm /*noValidate*/ onSubmit={formikProps.handleSubmit} onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) => handleKeyDown(e, formikProps)}>
                {step === 1 && <RegistrierungEmail {...formikProps} showGesundheitsprogrammHinweis={isGesundheitsprogrammUser} />}
                {step === 2 && <RegistrierungBetriebskonzeptPasswort {...formikProps} loginFailed={isLoginError} />}
                {step === 3 && <RegistrierungStammdaten {...formikProps} betriebskonzeptImkerData={betriebskonzeptImkerData} teilnahmeErneuern={teilnahmeErneuern} />}
                {step === 4 && <RegistrierungImkerAngaben {...formikProps} kickoffTerminAuswahl={kickoffTerminAuswahl} teilnahmeErneuern={teilnahmeErneuern} imkerStatus={imkerStatus!} />}
                {step === 5 && <RegistrierungBestaetigung {...formikProps} kickoffTerminAuswahl={kickoffTerminAuswahl} isSubmitFailed={isSubmitFailed} />}
                <div>
                  {step > 3 && (
                    <Button type="button" onClick={handlePreviousStep}>
                      {t("anmeldung.zurueck")}
                    </Button>
                  )}
                  {step < 5 && !isLoadingSubmit && (
                    <Button type="button" onClick={() => handleNextStep(formikProps)}>
                      {t("anmeldung.weiter")}
                    </Button>
                  )}
                  {isLoadingSubmit && (
                    <Button type="button" disabled>
                      <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
                    </Button>
                  )}
                  {step === 5 && !isLoadingSubmit && (
                    <Button type="button" onClick={() => handleNextStep(formikProps)} disabled={formikProps.isSubmitting}>
                      {t("anmeldung.anmelden")}
                    </Button>
                  )}
                </div>
              </CustomForm>
            )}
          </Formik>
        </LaodingDisable>
      )}
    </>
  );
}

export default Registrierung;
