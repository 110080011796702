import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../components/reusable/button";
import ButtonLink from "../components/reusable/buttonlink";
import personLogo from "../assets/images/pilotteilnehmerin_stauch_web.jpg";

function Uebersicht() {
  const { t, i18n } = useTranslation();

  const EinleitungText = styled.p`
    margin-top: 1rem;
    margin-bottom: 2rem;
  `;

  const EinleitungZitatWrapper = styled.div`
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
  `;

  const EinleitungZitatBild = styled.div`
    /* TODO: Gleiche Höhe wie Text, evtl. Flexbox? */
    height: 80%;
    margin: 5px 10px 0 0;

    img {
      border-radius: 3px;
    }
  `;

  const EinleitungZitatText = styled.p`
    font-style: italic;
  `;

  const EinleitungTextZitatPerson = styled.p`
    font-weight: bolder;
    margin-top: 1.5rem;
  `;

  const Table = styled.table`
    margin-left: auto;
    margin-right: auto;
    border-collapse: collapse;
    /* box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%); */

    tr:first-child th:first-child {
      border-top-left-radius: 5px;
    }
    tr:first-child th:last-child {
      border-top-right-radius: 5px;
    }
    tr:last-child th:first-child {
      border-bottom-left-radius: 5px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 5px;
    }
  `;

  const THead = styled.th`
    background-color: #fac000;
    padding: 8px;
    border: 1px solid #ffffff76;
    vertical-align: top;

    transition: 0.3s;
    &:hover {
      background-color: #f9cf43;
    }
  `;

  const THeadSub = styled(THead)`
    padding-left: 1rem;
    font-weight: normal;
  `;

  const TRow = styled.tr`
    border: 1px solid black;
    background-color: #e6e600;
  `;

  const TCol = styled.td`
    background-color: #e6e600;
    padding: 8px;
    border: 1px solid #ffffff76;

    transition: 0.3s;
    &:hover {
      background-color: #eaea5b;
    }
  `;

  const ButtonRow = styled.div`
    margin-top: 30px;
    text-align: end;
  `;

  const einleitungLinkURL = t("uebersicht.einleitungTextLinkUrl");
  const teilnahmeLinkURL = t("uebersicht.teilnahmeTextLinkUrl");

  return (
    <div>
      <EinleitungText>
        <Trans i18nKey="uebersicht.einleitungText">
          <a className="inlineAnchor" target="__blank" href={einleitungLinkURL}></a>
        </Trans>
      </EinleitungText>
      <EinleitungZitatWrapper>
        <EinleitungZitatBild>
          <img alt="person" src={personLogo}></img>
        </EinleitungZitatBild>
        <EinleitungZitatText>
          {t("uebersicht.einleitungTextZitat")}
          <EinleitungTextZitatPerson>{t("uebersicht.einleitungTextZitatPerson")}</EinleitungTextZitatPerson>
        </EinleitungZitatText>
      </EinleitungZitatWrapper>

      <EinleitungText>
        <Trans i18nKey="uebersicht.teilnahmeText">
          <Link to={teilnahmeLinkURL} className="inlineAnchor"></Link>
        </Trans>
      </EinleitungText>
      <Table>
        <TRow>
          <THead></THead>
          <THead>{t("uebersicht.programmTitel")}</THead>
        </TRow>
        <TRow>
          <THead>{t("uebersicht.teilnahmegebuehr")}</THead>
          <TCol>{t("uebersicht.teilnahmegebuehrInhalt")}</TCol>
        </TRow>
        <TRow>
          <THead>{t("uebersicht.anmeldeschluss")}</THead>
          <TCol>{t("uebersicht.anmeldeschlussInhalt")}</TCol>
        </TRow>
        <TRow>
          <THead>{t("uebersicht.videokonferenz")}</THead>
          <TCol>
            <Trans i18nKey="uebersicht.videokonferenzInhalt">
              <a className="inlineAnchor" target="_blank" href={t("uebersicht.videokonferenzInhalt_link").toString()}></a>
            </Trans>
          </TCol>
        </TRow>
        <TRow>
          <THead>{t("uebersicht.dienstleistungen")}</THead>
          <TCol></TCol>
        </TRow>
        <TRow>
          <THeadSub>{t("uebersicht.erfahrungsaustausch")}</THeadSub>
          <TCol>{t("uebersicht.erfahrungsaustauschInhalt")}</TCol>
        </TRow>
        <TRow>
          <THeadSub>{t("uebersicht.sms")}</THeadSub>
          <TCol>{t("uebersicht.smsInhalt")}</TCol>
        </TRow>
        <TRow>
          <THeadSub>{t("uebersicht.coaching")}</THeadSub>
          <TCol>{t("uebersicht.coachingInhalt")}</TCol>
        </TRow>
        <TRow>
          <THeadSub>{t("uebersicht.plakette")}</THeadSub>
          <TCol>{t("uebersicht.plaketteInhalt")}</TCol>
        </TRow>
        <TRow>
          <THeadSub>{t("uebersicht.informationsveranstaltung")}</THeadSub>
          <TCol>{t("uebersicht.kickoffInhalt")}</TCol>
        </TRow>
      </Table>
      <ButtonRow>
        {new Date() < new Date("2025-07-01") ? (
          <>
            <p style={{ color: "red", display: "inline", marginRight: "10px" }}>{t("uebersicht.anmeldungDatumMoeglich")}</p>
            <Button disabled>
              <span>{t("uebersicht.zurAnmeldung")}</span>
            </Button>
          </>
        ) : (
          <Button>
            <ButtonLink to="/neuanmeldung">{t("uebersicht.zurAnmeldung")}</ButtonLink>
          </Button>
        )}
        <Button>
          {/* TODO: AGB's in anderen Sprachen  */}
          <ButtonLink to="/agb">{t("uebersicht.agb")}</ButtonLink>
        </Button>
      </ButtonRow>
    </div>
  );
}

export default Uebersicht;
